<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="2" md="2" align="center"> </v-col>
            <v-col cols="12" sm="2" md="1" align="center"
              >Initial pressure
            </v-col>
            <v-col cols="12" sm="2" md="1" align="center">Final pressure</v-col>
            <v-col cols="12" sm="2" md="1" align="center">Time</v-col>
            <v-col cols="12" sm="2" md="2" align="center">Continous flow</v-col>
            <!-- <v-col cols="12" sm="2" md="2" align="center">Effluent</v-col> -->
            <v-col cols="12" sm="2" md="1" align="center">1 Hr PBU</v-col>
            <v-col cols="12" sm="2" md="1" align="center">24 Hr PBU</v-col>
          </v-row>

          <v-row>
            <v-row
              class="rowh2"
              dense
              align="center"
              justify="center"
              v-for="item in test_bo"
              :key="item.id"
            >
              <v-col cols="12" sm="2" md="2">
                <div style="font-weight: bold; margin-bottom: 20px">
                  <span>{{ item.label }}</span>
                </div></v-col
              >
              <v-col cols="12" sm="2" md="1">
                <v-text-field
                  align-self="center"
                  class="rowh"
                  autocomplete="off"
                  return-object
                  v-model="item.i_pressure"
                  outlined
                  @input="changes"
                  type="number"
                  :readonly="test_readonly"
                  hide-spin-buttons
                  dense
                  @change="get_failures()"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="2" md="1">
                <v-text-field
                  align-self="center"
                  class="rowh"
                  autocomplete="off"
                  return-object
                  v-model="item.f_pressure"
                  outlined
                  @input="changes"
                  type="number"
                  :readonly="test_readonly"
                  hide-spin-buttons
                  dense
                  @change="get_failures()"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="2" md="1">
                <v-text-field
                  align-self="center"
                  class="rowh"
                  autocomplete="off"
                  return-object
                  v-model="item.time"
                  type="number"
                  @input="changes"
                  outlined
                  :readonly="test_readonly"
                  hide-spin-buttons
                  dense
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="2" md="2">
                <v-select
                  style="padding-right: 8px"
                  v-model="item.c_flow"
                  outlined
                  return-object
                  @input="changes"
                  dense
                  :readonly="test_readonly"
                  :items="['Y', 'No']"
                >
                </v-select>
              </v-col>
              <!-- <v-col cols="12" sm="2" md="2">
                      <v-select
                        :items="fluids"
                        v-model="item.fluid_id"
                        item-value="id"
                        item-text="label"
                        outlined
                        dense
                        return-object
                      >
                      </v-select>
                    </v-col> -->
              <v-col cols="12" sm="2" md="1">
                <v-text-field
                  class="rowh"
                  autocomplete="off"
                  return-object
                  v-model="item.f_pressure_12hrs"
                  type="number"
                  @input="changes"
                  outlined
                  :readonly="test_readonly"
                  hide-spin-buttons
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="1">
                <v-text-field
                  class="rowh"
                  return-object
                  autocomplete="off"
                  v-model="item.f_pressure_24hrs"
                  type="number"
                  outlined
                  @input="changes"
                  :readonly="test_readonly"
                  hide-spin-buttons
                  dense
                >
                </v-text-field>
              </v-col> </v-row
            ><br />
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import allquery from "../graphql/WellTest/TestForm/allQuery.gql";
export default {
  components: {},
  props: {
    test_bo: Array,
    test_readonly: Boolean,
    failures_list: Array,
    editedItem: Object,
  },
  data() {
    return {
      details: [],
      all_failures_list: [],
      test_failures: [],
      barriers: [],
      well_failures: [],
    };
  },

  mounted() {
    this.getdata();
    this.details = Object.assign([], this.test_bo);
    this.order = Math.max(...this.test_bo.map((item) => item.csg_order));
    this.well_failures = Object.assign([], this.failures_list);
    this.$emit("changes", this.details, this.failures_list);
  },
  computed: {},
  watch: {},
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    async getdata() {
      let r = await this.$requette(allquery);
      if (r.ok) {
        this.all_failures_list = r.data.failures_list;
        this.barriers = r.data.wmf_list;
      }
    },
    valuechanged1H_pressure() {},
    valuechanged12H_pressure() {},
    valuechanged_pressure(item) {
      if (item.i_pressure && item.f_pressure) {
        if (item.i_pressure != item.f_pressure) {
          if (
            Math.abs(item.i_pressure - item.f_pressure) >=
            (item.i_pressure * 3) / 100
          )
            this.set_falure(item, 212, 1);
          else if (
            Math.abs(item.i_pressure - item.f_pressure) <
            (item.i_pressure * 3) / 100
          )
            this.set_falure(item, 214, 1);
        }
      }
    },
    set_falure(item, failure, gid) {
      let fail = {
        id: null,
        state: 1,
        cmp_code: item.cmp_code,
        cmp_id: item.cmp_id,
        failure_id: failure,
        welltest_id: this.editedItem.id,
        well_tubular_id: item.well_tubular_id,
        g_id: gid,
        testtype: this.editedItem.testtype_id,
      };
      this.test_failures.push(
        this.$calc_single(this.barriers, this.all_failures_list, fail)
      );
    },

    get_failures() {
      this.details.forEach((item) => {
        this.communication_change(item);
        this.valuechanged_pressure(item);
      });
      //calculate multiples
      let multiples = this.$get_multiples(
        this.well_failures,
        this.test_failures.filter((x) => x.cmp_code != "0" && x.failure_id > 0),
        this.barriers,
        this.all_failures_list,
        this.editedItem.id
      );

      // add multiples
      if (multiples)
        if (multiples.length > 0) {
          var max = multiples.reduce(function (prev, current) {
            if (+current.failure_taux < +prev.failure_taux) {
              return current;
            } else {
              return prev;
            }
          });
          this.test_failures.push(max);
        }
      // if (multiples) multiples.forEach((m) => this.test_failures.push(m));
      this.$emit("changes", this.details, this.test_failures);
    },
    changes() {
      this.get_failures();
      this.$emit("changes", this.details, this.failures_list);
    },
  },
};
</script>
<style>
.rowh0 {
  width: 100% !important;
  height: 45px !important;
}
</style>
